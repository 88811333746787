import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from '@material-ui/core';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)`
  font-weight: bold;
  background-color: #f9f9f9;
`;

const StatusChip = styled.div<{ status: string }>`
  padding: 4px 12px;
  border-radius: 16px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;

  ${({ status, theme }) => {
    switch (status.toLowerCase()) {
      case 'hot':
        return `
          background-color: ${theme.colors.r100};
          color: ${theme.colors.r500};
          border: 1px solid ${theme.colors.r300};
        `;
      case 'warm':
        return `
          background-color: ${theme.colors.y100};
          color: ${theme.colors.y500};
          border: 1px solid ${theme.colors.y300};
        `;
      case 'frozen':
        return `
          background-color: ${theme.colors.b100};
          color: ${theme.colors.b500};
          border: 1px solid ${theme.colors.b300};
        `;
      default:
        return `
          background-color: ${theme.colors.n100};
          color: ${theme.colors.n500};
          border: 1px solid ${theme.colors.n300};
        `;
    }
  }}
`;

export interface LeadResponse {
  items: Lead[];
  total: number;
}
export interface Lead {
  identification: string;
  rating: 'hot' | 'cold' | 'warm' | 'frozen';
  lead_status: string;
  proposal_status: number;
  sub_group: string;
  contact_name: string;
  promoter_name: string;
  location: string;
  phone: string;
  IsDecisionMaker: boolean;
  last_return_date: string;
}

interface LeadsTableProps {
  leads: Lead[];
  page: number;
  total: number;
  rowsPerPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const STATUS_ENUM = {
  hot: 'Quente',
  cold: 'Frio',
  warm: 'Morno',
  frozen: 'Congelado'
};

const PROPOSAL_STATUS_ENUM: any = {
  1: 'Em Progresso',
  2: 'Processando',
  3: 'Aprovado',
  4: 'Rejeitado',
  5: 'Completo',
  6: 'Cancelado',
  7: 'Assinado'
};

const LeadsTable = ({
  leads,
  page,
  total,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange
}: LeadsTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome da empresa ou CPF</StyledTableCell>
            <StyledTableCell>Status do lead</StyledTableCell>
            <StyledTableCell>Status da proposta</StyledTableCell>
            <StyledTableCell>Subgrupo do promotor</StyledTableCell>
            <StyledTableCell>Endereço do lead</StyledTableCell>
            <StyledTableCell>Contato do lead</StyledTableCell>
            <StyledTableCell>Data de retorno</StyledTableCell>
            <StyledTableCell>Executivo responsavel</StyledTableCell>
            <StyledTableCell>Telefone</StyledTableCell>
            <StyledTableCell>Pessoa Decisora</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead, index) => (
            <TableRow key={index}>
              <TableCell>{lead.identification}</TableCell>
              <TableCell>
                <StatusChip status={lead.rating}>
                  {STATUS_ENUM[lead.rating]}
                </StatusChip>
              </TableCell>
              <TableCell>
                {PROPOSAL_STATUS_ENUM[lead.proposal_status]}
              </TableCell>
              <TableCell>{lead.sub_group}</TableCell>
              <TableCell>{lead.location}</TableCell>
              <TableCell>{lead.contact_name}</TableCell>
              <TableCell>{lead.last_return_date}</TableCell>
              <TableCell>{lead.promoter_name}</TableCell>
              <TableCell>{lead.phone}</TableCell>
              <TableCell>{lead.IsDecisionMaker ? 'Sim' : 'Não'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </TableContainer>
  );
};

export default LeadsTable;
