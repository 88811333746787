import IconFilterList from '@material-ui/icons/FilterList';
import { useEffect, useRef, useState } from 'react';
import { InputField, Button } from '../../components/_Atoms';
import { StyledBoxPage } from './styles';
import NavigationHeader from '../../components/Navegation';
import LeadsFilterForm, { LeadsFilters } from './components/filter';
import LeadsTable, { Lead, LeadResponse } from './components/table';
import { ROUTES } from '../../constants/routes.constants';
import { get } from '../../services/axios.service';

const LeadsManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState<LeadsFilters | null>(null);
  const [data, setData] = useState<Lead[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [subGroups, setSubGroups] = useState([]);
  const [promotors, setPromotors] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const searchDebounceRef = useRef<NodeJS.Timeout | null>(null);

  const handleFilter = (filters: LeadsFilters) => {
    setActiveFilters(filters);
    setPage(0);
  };

  const buildQueryParams = (
    filters: LeadsFilters | null,
    q: string,
    page: number,
    limit: number
  ) => {
    const params: Record<string, string> = {};

    if (filters) {
      if (filters.proposalStatus.length > 0) {
        params.proposalStatus = filters.proposalStatus.join(',');
      }

      if (filters.promoterGroup.length > 0) {
        params.promoterSubGroup = filters.promoterGroup.join(',');
      }

      if (filters.executive.length > 0) {
        params.promoterName = filters.executive.join(',');
      }

      if (filters.leadGenerationPeriod.length > 0) {
        params.period = filters.leadGenerationPeriod.join(',');
      }

      if (filters.leadStatus.length > 0) {
        params.rating = filters.leadStatus.join(',');
      }

      if (filters.dateRange.start) {
        params.startDate = filters.dateRange.start;
      }

      if (filters.dateRange.end) {
        params.endDate = filters.dateRange.end;
      }
    }

    if (q && q.trim() !== '') {
      params.q = q.trim();
    }

    params.page = String(page + 1);
    params.limit = String(limit);

    return params;
  };

  const buildQueryString = (params: Record<string, string> | null) => {
    if (params) {
      const queryString = new URLSearchParams(
        Object.entries(params).map(([key, value]) => [key, value.toString()])
      ).toString();

      return `?${queryString}`;
    }

    return null;
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getSubGroup();
    getPromotors();
  }, []);

  useEffect(() => {
    const fetchLeads = async () => {
      await getItems(activeFilters, searchTerm, page, rowsPerPage);
    };
    // console.log(activeFilters, buildQueryString(activeFilters));
    fetchLeads();
  }, [activeFilters, searchTerm, page, rowsPerPage]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    searchDebounceRef.current = setTimeout(() => {
      setPage(0);
    }, 500);
    return () => {
      if (searchDebounceRef.current) {
        clearTimeout(searchDebounceRef.current);
      }
    };
  }, [searchTerm]);

  const getSubGroup = async () => {
    try {
      const response: any = await get('/propostas/subgrupos');

      if (response.data) {
        setSubGroups(response.data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPromotors = async () => {
    try {
      const response: any = await get('/backoffice/promotors', {}, true);

      setPromotors(response);
    } catch (e) {
      console.error(e);
    }
  };

  const getItems = async (
    filters?: LeadsFilters | null,
    q = '',
    currentPage = 0,
    limit = 10
  ) => {
    try {
      const params = buildQueryParams(filters || null, q, currentPage, limit);
      const queryString = buildQueryString(params);

      const response: LeadResponse = await get(
        `/backoffice/leads${queryString || ''}`,
        {},
        true
      );
      setData(response.items);
      setTotal(response.total);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledBoxPage>
      <NavigationHeader
        previousRoutes={[
          { routeName: 'Início', routePath: ROUTES.MAIN_MENU },
          { routeName: 'Gestão de Leads', routePath: '' }
        ]}
        currentRouteName="Leads"
        title="Leads"
        description="Gerenciar leads atuais."
      />

      <div style={{ marginBottom: '24px' }}>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            marginBottom: '16px',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <InputField
            label="Procurar"
            placeholder="Buscar leads..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: '100%' }}
            labelStyles={{ width: '100%' }}
          />
          <Button
            width="large"
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            IconBefore={() => <IconFilterList />}
            value="Filtros avançados"
          />
        </div>

        {showFilters && (
          <LeadsFilterForm
            onFilter={handleFilter}
            subGroups={subGroups}
            promotors={promotors}
          />
        )}
      </div>

      <LeadsTable
        leads={data}
        page={page}
        rowsPerPage={rowsPerPage}
        total={total}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledBoxPage>
  );
};

export default LeadsManagement;
